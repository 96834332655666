import { imagesFormat, videosFormat } from "../constants/dataFormat";

export const validateStock = (
  products,
  selected,
  almacenId,
  validateAvailability = true
) => {
  return products
    .map((item) => ({
      ...item,
      id: item._id,
      existencia: validateAvailability
        ? item.existencia.filter(({ almacen_id }) => almacen_id === almacenId)
        : item.existencia,
    }))
    .filter((item) => !selected.includes(item._id))
    .filter((item) =>
      validateAvailability
        ? item.existencia?.reduce(
            (sum, { existencia }) => sum + (existencia || 0),
            0
          ) > 0
          ? true
          : item.continuar_vendiendo
        : true
    )
    .map((item) => ({
      ...item,
      existencia: validateAvailability
        ? item.existencia.filter(
            ({ almacen_id }) => Number(almacen_id) === Number(almacenId)
          )
        : item.existencia,
      ...(item.isParent && {
        children: item.variants.map(({ variantId }) => ({
          ...variantId,
          id: variantId._id,
        })),
        variants: undefined,
      }),
    }));
};

export const getImageVideoUrl = (files = [], type = "image", getAll = true) => {
  if (!files) {
    return "";
  }
  let validFormats = type === "image" ? imagesFormat : videosFormat;
  let validFiles = [];
  for (let i = 0; i < validFormats.length; i++) {
    files.forEach((image) => {
      if (image !== null) {
        if (image.includes(validFormats[i])) {
          validFiles.push(image);
        }
      }
    });
  }

  if (getAll) {
    return validFiles;
  } else {
    if (validFiles.length > 0) {
      return validFiles[0];
    }
    return "";
  }
};

export const validateStockFromLocation = (location_id, product) => {
  if (!location_id || !product) {
    return false;
  }

  if (product.continuar_vendiendo) {
    return true;
  }

  const stock = product.existencia.find(
    (stock) => stock.almacen_id === location_id
  );

  if (!stock) {
    return false;
  }

  return stock.existencia > 0 || product.continuar_vendiendo;
};
