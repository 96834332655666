import {
  Button,
  Card,
  Icon,
  TextField,
  TextStyle,
  Tooltip,
} from "@shopify/polaris";
import {
  CircleInformationMajor,
  MobileCancelMajor,
  TickMinor,
} from "@shopify/polaris-icons";
import { Col, notification, Row } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addShoppingCartArticles } from "../../../actions/ShoppingCartActions";
import { NumberFormat } from "../../../utils/Formats";

import { getDiscountsShoppingCart } from "../../../reducers";
import Colors from "../../../styles/Colors";
import { getProductValidations } from "../../../utils/orderValidations";
import { getImageVideoUrl } from "../../../utils/productValidators";
import "./CardItem.css";

const CardItem = ({
  discounts,
  itemData: itemDataD,
  onClickImage,
  showTax,
  almacenId,
  onSelectVariant,
  clientCurrency = {
    id: 1,
    nombre: "Moneda nacional",
    claveFiscal: "MXN",
    simbolo: "$",
    tipoCambio: 1,
  },
  currencies,
  canShowStock = false,
  properties = {},
  styleCard = "CardItem",
  mode = "view",
}) => {
  const dispatch = useDispatch();
  const { discounts: discountsForProduct = [], priceToUse = null } =
    useSelector(getDiscountsShoppingCart);
  const itemData = {
    ...itemDataD,
    existencia: itemDataD.existencia.filter(
      (i) => Number(i.almacen_id) === Number(almacenId)
    ),
  };
  const minimum = useRef(itemData.minimum ? itemData.minimum : 1);
  const factorVenta = useRef(itemData.factor_venta ? itemData.factor_venta : 1);
  const quantityAddedRef = useRef(null);
  const [percentage, setPercentage] = useState(0);
  const [precio, setPrecio] = useState(0);
  const [quantity, setQuantity] = useState(String(minimum.current));
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [fieldsError, setFieldsError] = useState(null);
  const [quantityAdded, setQuantityAdded] = useState(String(minimum.current));
  const [stockItem, setStockItem] = useState(0);

  const { descuentoPromocion, descuentoVolumen, descuentoMaximo } = discounts;
  const currency = currencies?.find(
    ({ label }) =>
      label === (mode === "edit" ? "MXN" : clientCurrency.claveFiscal)
  );

  let precioEmpresa =
    itemDataD.precios.find(
      ({ precio_empresa_id }) => precio_empresa_id === priceToUse
    ) ||
    itemDataD.precios.find(({ precio_empresa_id }) => precio_empresa_id === 42);

  const getCurrencyConvertion = useCallback(
    (price) => {
      if (precioEmpresa) {
        const moneda = precioEmpresa?.moneda;
        const tipoCambio = currency?.tipoCambio;

        if (moneda) {
          if (clientCurrency.claveFiscal === "MXN") {
            return price * tipoCambio;
          } else if (moneda === "MXN") {
            return price / tipoCambio;
          } else {
            return price;
          }
        }
        return 0;
      }
      return 0;
    },
    [clientCurrency?.claveFiscal, currency?.tipoCambio, precioEmpresa]
  );

  useEffect(() => {
    quantityAddedRef.current = quantityAdded;
  });

  useEffect(() => {
    const { percentDefault, price } = getProductValidations({
      product: itemDataD,
      descMaximos: descuentoMaximo,
      descPromoAplicada: descuentoPromocion,
      descVolumenAplicada: descuentoVolumen,
      discounts: discountsForProduct,
      priceToUse,
    });
    setPercentage(percentDefault);
    setPrecio(price);

    // Valida si el articulo tiene un mínimo y un factor de venta
    if (
      !itemData.minimum ||
      (itemData.minimum === 1 && itemData?.factor_venta === 1)
    ) {
      setTooltipVisible(false);
    } else {
      setQuantity(quantityAddedRef.current);
    }

    setQuantityAdded(String(minimum.current));
  }, [
    descuentoMaximo,
    descuentoPromocion,
    descuentoVolumen,
    discountsForProduct,
    itemDataD,
    priceToUse,
    itemData._id,
    itemData.linea_articulo,
    itemData.precios,
    itemData.factor_venta,
    itemData.minimum,
    getCurrencyConvertion,
  ]);

  useEffect(() => {
    let stock = 0;

    itemData?.existencia?.forEach(({ existencia }) => {
      stock += existencia;
    });

    setStockItem(stock);
  }, [itemData?.existencia]);

  function quantityValidators(value) {
    if (value < minimum.current) {
      setFieldsError(`Mínimo ${minimum.current}`);
      return;
    }
    if (value > itemData.maximum) {
      if (itemData.maximum && itemData.maximum > 0) {
        setFieldsError(`Máximo ${itemData.maximum}`);
      } else {
        setFieldsError("");
      }
      if (value % factorVenta.current !== 0) {
        setFieldsError(`Incrementos de ${factorVenta.current}`);
        return;
      }
      return;
    }
    if (value % factorVenta.current !== 0) {
      setFieldsError(`Incrementos de ${factorVenta.current}`);
      return;
    }
    setFieldsError("");
  }

  function handleFieldChange(value) {
    quantityValidators(value);
    setQuantity(value);

    // Se obtiene el porcentaje de descuento por volumen
    discounts.descuentoVolumen.forEach((i) => {
      i.volumenes.forEach((j) => {
        if (value >= j.cantidadMinima) {
          setPercentage(j.porcentaje);
        }
      });
    });
  }

  function getDiscount() {
    let discount =
      percentage > 0 ? precio - precio * (percentage / 100) : precio;

    return discount;
  }

  function validatePriceWithTax() {
    let price = getDiscount();
    let impuestos = itemData.impuestos?.filter((j) => j.porcentaje > 0) || [];
    let summary = 0;
    impuestos.forEach((j) => (summary = summary + 1 * (j.porcentaje / 100)));
    const tax = 1 + summary;
    price = showTax ? price * tax : price;
    price = Math.round((price + Number.EPSILON) * 100) / 100;
    price = getCurrencyConvertion(price);

    return Number(price || 0).toLocaleString("en", NumberFormat);
  }

  function getPercentage() {
    // Porcentaje de descuento, sólo para mostrarlo al cliente en la interfaz
    let percent = (1 - getDiscount() / precio) * 100;
    return Math.round((percent + Number.EPSILON) * 100) / 100;
  }

  function validatePriceWithTaxMain() {
    let price = precio;
    let impuestos = itemData.impuestos?.filter((j) => j.porcentaje > 0) || [];
    let summary = 0;
    impuestos.forEach((j) => (summary = summary + 1 * (j.porcentaje / 100)));
    const tax = 1 + summary;
    price = showTax ? price * tax : price;
    price = Math.round((price + Number.EPSILON) * 100) / 100;
    price = getCurrencyConvertion(price);

    return Number(price || 0).toLocaleString("en", NumberFormat);
  }

  function onBlurTextField(value) {
    quantityValidators(value);

    const existencia = itemData.existencia.reduce(
      (prev, current) => prev + current.existencia,
      0
    );

    if (String(quantity) === "" || Number(quantity) <= 0) {
      setQuantity(String(minimum.current));
      return;
    }

    if (existencia > 0 && !itemData.continuar_vendiendo) {
      if (Number(quantity) > existencia) setQuantity(String(existencia));
      return;
    }
  }

  function onAddArticle() {
    const itemToAdd = {
      article: itemData,
      cantidad: Number(quantity),
      descuentoMaximo,
      descuentoVolumen,
      descuentoPromocion,
      showTax,
    };

    notification.open({
      message: <TextStyle>Producto agregado en tu pedido</TextStyle>,
      description: (
        <div className="flex flex-row mt-4">
          <img
            alt=""
            style={{ width: 70, height: 70 }}
            src={
              getImageVideoUrl(itemData?.imagen, "image", false) ||
              "/Default Photo.png"
            }
          />
          <div className="flex flex-col ml-6">
            <TextStyle>{itemData.nombre}</TextStyle>
            <TextStyle>
              {itemData.isParent
                ? itemData.clave.split(" ")[0]
                : itemData.clave}
            </TextStyle>
          </div>
        </div>
      ),
      duration: 1.5,
      icon: <Icon source={TickMinor} />,
      closeIcon: <Icon source={MobileCancelMajor} />,
    });
    handleFieldChange(minimum.current);
    dispatch(addShoppingCartArticles(itemToAdd));
  }

  function validateMinMaxPrice(min = true) {
    const prices = itemData.variants.map((i) => i.variantId.precios).flat();
    let minItem = _.minBy(prices, ({ precio }) => precio)
      ? _.minBy(prices, ({ precio }) => precio).precio
      : 0;
    minItem = getCurrencyConvertion(minItem);
    let maxItem = _.maxBy(prices, ({ precio }) => precio)
      ? _.maxBy(prices, ({ precio }) => precio).precio
      : 0;
    maxItem = getCurrencyConvertion(maxItem);
    return min ? minItem : maxItem;
  }

  const showStock = canShowStock ? (
    <TextStyle>{stockItem.toLocaleString("en-US")} disponibles</TextStyle>
  ) : (
    <TextStyle>Disponible</TextStyle>
  );

  if (styleCard === "CardItem") {
    return (
      <Card sectioned>
        <div className="card-image-container">
          <img
            alt=""
            className="card-image cursor-pointer"
            onClick={mode === "view" ? onClickImage : () => {}}
            src={
              getImageVideoUrl(itemData?.imagen, "image", false) ||
              "/Default Photo.png"
            }
          />
        </div>

        <div className="flex flex-col justify-between">
          <p
            className="card-name pt-3 cursor-pointer"
            onClick={mode === "view" ? onClickImage : () => {}}
          >
            <Tooltip content={itemData?.nombre} preferredPosition="mostSpace">
              <TextStyle>{itemData?.nombre}</TextStyle>
            </Tooltip>
          </p>

          <div className="pt-2">
            <TextStyle variation="subdued">
              {itemData?.isParent
                ? itemData.clave.split(" ")[0]
                : itemData.clave}
            </TextStyle>
          </div>

          <div className="pt-2">
            {stockItem > 0 ? showStock : <TextStyle>Bajo pedido</TextStyle>}
          </div>

          {!itemData.isParent && (
            <div
              className="flex justify-between cursor-pointer"
              onClick={mode === "view" ? onClickImage : () => {}}
            >
              {getPercentage() > 0 ? (
                <div className="flex justify-end">
                  <div className="mr-6 line-through">
                    <TextStyle variation="subdued">
                      ${validatePriceWithTaxMain()}
                    </TextStyle>
                  </div>
                  <TextStyle variation="subdued">{`(${getPercentage()}%)`}</TextStyle>
                </div>
              ) : (
                <></>
              )}
              <TextStyle variation={getPercentage() > 0 ? "negative" : null}>
                ${validatePriceWithTax()}
              </TextStyle>
            </div>
          )}
          {itemData.isParent && (
            <div
              className="flex justify-between cursor-pointer mb-1"
              onClick={mode === "view" ? onClickImage : () => {}}
            >
              <TextStyle variation="subdued">
                Desde $
                {Number(validateMinMaxPrice(true)).toLocaleString(
                  "en",
                  NumberFormat
                )}
              </TextStyle>
              <TextStyle variation="subdued">
                - hasta $
                {Number(validateMinMaxPrice(false)).toLocaleString(
                  "en",
                  NumberFormat
                )}
              </TextStyle>
            </div>
          )}
          {itemData.isParent ? (
            <Row className="mt-14" gutter={[4, 4]}>
              <Col className="selection-button" span={24}>
                <Button
                  primary
                  fullWidth
                  onClick={() => onSelectVariant(itemData._id)}
                >
                  Seleccionar una opción
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              <Row className="mt-8" gutter={[4, 4]}>
                <Col xs={24} sm={24}>
                  <div className="error-space">
                    <TextField
                      type="number"
                      min={1}
                      max={
                        itemData?.existencia.reduce(
                          (prev, current) => prev + current.existencia,
                          0
                        ) <= 0 ||
                        itemData?.continuar_vendiendo ||
                        itemData.maximum === 0 ||
                        !itemData.maximum
                          ? null
                          : itemData?.existencia?.reduce(
                              (prev, current) => prev + current.existencia,
                              0
                            )
                      }
                      value={String(quantity)}
                      onChange={handleFieldChange}
                      onBlur={() => onBlurTextField(Number(quantity))}
                      suffix={
                        <div className="flex items-center">
                          {itemData && itemData?.unidadmed}
                          {tooltipVisible && (
                            <div className="tooltip-space cursor-pointer">
                              <Tooltip
                                content={
                                  <>
                                    <p>Mínimo: {minimum.current}</p>
                                    <p>
                                      Máximo:{" "}
                                      {itemData.maximum === 0
                                        ? "Ilimitado"
                                        : itemData.maximum}
                                    </p>
                                    <p>Factor: {factorVenta.current}</p>
                                  </>
                                }
                                preferredPosition="mostSpace"
                              >
                                <Icon
                                  source={CircleInformationMajor}
                                  color="inkLightest"
                                />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      }
                      error={fieldsError}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col span={24}>
                  {!fieldsError && (
                    <Button
                      primary
                      fullWidth
                      onClick={mode === "view" ? onAddArticle : () => {}}
                      disabled={fieldsError}
                    >
                      Agregar
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          )}
        </div>
      </Card>
    );
  }

  if (styleCard === "OnlineStore") {
    const { showKey, showAvailability, showDisccount, showUnitButton } =
      properties;
    return (
      <div
        className="collection-section-container flex flex-col"
        onClick={mode === "view" ? onClickImage : () => {}}
      >
        <div className="collection-section-image-container relative w-full aspect-square">
          <img
            className="object-cover h-full"
            src={
              getImageVideoUrl(itemData?.imagen, "image", false) ||
              "/Default Photo.png"
            }
            alt={itemData.nombre}
          />
          {showDisccount && getPercentage() > 0 && (
            <div
              className="absolute"
              style={{
                color: "white",
                backgroundColor: "#1C2260",
                right: 0,
                top: 8,
                padding: "4px 12px",
              }}
            >
              {`-${getPercentage()}%`}
            </div>
          )}
          {showUnitButton && (
            <div
              className={`hoverable hidden absolute inset-0 justify-center items-center`}
              style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
            >
              <div
                className="flex flex-col"
                style={{ maxWidth: 150 }}
                onClick={(event) => event.stopPropagation()}
              >
                {itemData.isParent ? (
                  <>
                    <button
                      style={{
                        backgroundColor: "#1C2260",
                        color: "white",
                        fontWeight: 600,
                        padding: "8px 16px",
                        marginTop: 8,
                        borderRadius: 4,
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        onSelectVariant(itemData._id);
                      }}
                    >
                      Seleccionar
                    </button>
                  </>
                ) : (
                  <>
                    <div className="error-space">
                      <TextField
                        type="number"
                        min={1}
                        max={
                          itemData?.existencia.reduce(
                            (prev, current) => prev + current.existencia,
                            0
                          ) <= 0 ||
                          itemData?.continuar_vendiendo ||
                          itemData.maximum === 0 ||
                          !itemData.maximum
                            ? null
                            : itemData?.existencia?.reduce(
                                (prev, current) => prev + current.existencia,
                                0
                              )
                        }
                        value={String(quantity)}
                        onChange={handleFieldChange}
                        onBlur={() => onBlurTextField(Number(quantity))}
                        suffix={
                          <div className="flex items-center">
                            {itemData && itemData?.unidadmed}
                            {tooltipVisible && (
                              <div className="tooltip-space cursor-pointer">
                                <Tooltip
                                  content={
                                    <>
                                      <p>Mínimo: {minimum.current}</p>
                                      <p>
                                        Máximo:{" "}
                                        {itemData.maximum === 0
                                          ? "Ilimitado"
                                          : itemData.maximum}
                                      </p>
                                      <p>Factor: {factorVenta.current}</p>
                                    </>
                                  }
                                  preferredPosition="mostSpace"
                                >
                                  <Icon
                                    source={CircleInformationMajor}
                                    color="inkLightest"
                                  />
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        }
                        error={fieldsError}
                      />
                    </div>
                    <button
                      style={{
                        backgroundColor: "#1C2260",
                        color: "white",
                        fontWeight: 600,
                        padding: "8px 16px",
                        marginTop: 8,
                        borderRadius: 4,
                      }}
                      onClick={
                        mode === "view"
                          ? (event) => {
                              event.preventDefault();
                              onAddArticle();
                            }
                          : () => {}
                      }
                      disabled={fieldsError}
                    >
                      Agregar
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <p
            className="my-2"
            style={{
              fontSize: 12,
              lineHeight: "20px",
            }}
          >
            {itemData.nombre}
          </p>
          {showKey && (
            <p
              className="my"
              style={{
                fontSize: 12,
                lineHeight: "20px",
                color: Colors.subdued,
              }}
            >
              {itemData.clave}
            </p>
          )}
          {showAvailability && (
            <p
              className="my-2"
              style={{
                fontSize: 12,
                lineHeight: "20px",
              }}
            >
              {stockItem > 0 ? showStock : <TextStyle>Bajo pedido</TextStyle>}
            </p>
          )}
          {!itemData.isParent && (
            <div className="flex cursor-pointer">
              {getPercentage() > 0 ? (
                <div className="flex justify-end">
                  <div className="mr-6 line-through">
                    <TextStyle variation="subdued">
                      ${validatePriceWithTaxMain()}
                    </TextStyle>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <TextStyle variation={getPercentage() > 0 ? "negative" : null}>
                ${validatePriceWithTax()}
              </TextStyle>
            </div>
          )}
          {itemData.isParent && (
            <div className="flex justify-between cursor-pointer mb-1">
              <TextStyle variation="subdued">
                Desde $
                {Number(validateMinMaxPrice(true)).toLocaleString(
                  "en",
                  NumberFormat
                )}
              </TextStyle>
              <TextStyle variation="subdued">
                - hasta $
                {Number(validateMinMaxPrice(false)).toLocaleString(
                  "en",
                  NumberFormat
                )}
              </TextStyle>
            </div>
          )}
        </div>
      </div>
    );
  }
};
export default CardItem;
